import { apiRequest } from '@glow/shared/lib/http';
import { dependOnValues, nonNullableMaybeRef } from '@glow/shared/lib/vueQuery';
import { useQuery } from '@tanstack/vue-query';
import { type NullableMaybeRef } from '@glow/shared/utils/types';

export type GeoAddressSuggestion = {
  title: string;
};

export function addressSuggest(query: string) {
  if (query.length < 3) return [];
  return apiRequest<GeoAddressSuggestion[]>('post', '/geo/address-suggest', {
    query,
  });
}

export function useAddressSuggest(query: NullableMaybeRef<string>) {
  return useQuery(
    ['geoAddressSuggest', nonNullableMaybeRef(query)],
    () => addressSuggest(unref(query)!),
    { enabled: dependOnValues([query]) }
  );
}
